import React from "react";

const ALLOWED_KEYS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
  "abcdefghijklmnopqrstuvwxyz" +
  "1234567890" +
  "!@#$%^&*()" +
  "`~-_=+[{]}\\|;:'\",<.>/?" +
  " ";

export default class TextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      typedText: "",
      wrongText: "",
      startTime: 0,
      currentWpm: "",
      isFocused: false,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="typing primary-light">
        <div
          className={"paper paper-shadow " + this.displayHighlight()}
          onKeyDown={this.onKeyDown}
          onBlur={this.onLoseFocus}
          onFocus={this.onFocus}
          tabIndex="-1"
          ref={(paper) => (this.paper = paper)}
        >
          <div className="typingcontainer">
            <div className={"instructions " + this.displayInstructions()}>
              <div className="innerInstructions">
                Click the paper and start typing!
              </div>
            </div>
            <div className="typingtext">{this.props.text}</div>
            <div className="typingtextfront">
              {this.getTypedText()}
              <span className="typingtextbadspan">{this.getWrongText()}</span>
              <span className="green">{this.displayEnter()}</span>
              <span className="next">
                {this.getNextChar(
                  this.state.typedText.length,
                  this.state.wrongText
                )}
              </span>
            </div>

            <div className="current"></div>
          </div>
        </div>
      </div>
    );
  }

  onFocus = (e) => {
    this.setState({ isFocused: true });
  };

  onLoseFocus = (e) => {
    new Promise((resolve) => setTimeout(resolve, 300)).then(() => {
      if (this.paper !== document.activeElement) {
        this.setState({ isFocused: false });
      }
    });
  };

  displayHighlight() {
    return !this.state.isFocused ? "highlight-on" : "highlight-off";
  }

  displayInstructions() {
    return this.state.isFocused ? "hidden" : "";
  }

  displayEnter() {
    return !this.getWrongText() &&
      this.getNextChar(this.state.typedText.length, this.state.wrongText) ===
        "\n"
      ? " Hit Enter! "
      : "";
  }

  componentDidUpdate(prevProps) {
    if (prevProps.restart === 0) {
      return;
    }
    if (
      prevProps.restart !== this.props.restart ||
      prevProps.textId !== this.props.textId
    ) {
      this.onReset();
      this.paper.focus();
    }
  }

  getTypedText = (e) => {
    return this.state.typedText;
  };

  allowed(key) {
    return ALLOWED_KEYS.includes(key);
  }

  onKeyDown = (e) => {
    e.preventDefault();

    let k = e.key;
    if (e.ctrlKey) {
      if (k === "r") {
        this.onReset();
        return;
      } else if (k === "f") {
        this.getNext();
        return;
      }
    }
    if (k === "Backspace") {
      let wrongText = this.state.wrongText;
      let typedText = this.state.typedText;

      if (wrongText) {
        this.setState({ wrongText: wrongText.slice(0, wrongText.length - 1) });
      } else if (typedText) {
        this.setState({ typedText: typedText.slice(0, typedText.length - 1) });
      }
    } else if (this.allowed(k) || k === "Enter" || k === "Tab") {
      if (k === "Enter") {
        k = "\n";
      } else if (k === "Tab") {
        k = "\t";
      }

      if (!this.state.typedText && !this.state.wrongText) {
        this.start();
      }

      let typedText = this.state.typedText;
      let wrongText = this.state.wrongText;

      if (this.doesNextCharMatch(wrongText, typedText, k)) {
        typedText += k;
        typedText = this.typeTabs(typedText);
        this.setState({ typedText: typedText });
      } else {
        wrongText += k;
        this.setState({ wrongText: wrongText });
      }

      this.updateWpm(wrongText, typedText);
    }
  };

  typeTabs(typedText) {
    while (
      typedText.length < this.props.text.length &&
      this.props.text.charAt(typedText.length) === "\t"
    ) {
      typedText += "\t";
    }
    return typedText;
  }

  getNext() {
    this.props.onNext();
  }

  onReset() {
    this.setState({
      typedText: "",
      wrongText: "",
      currentWpm: "",
      startTime: new Date().getTime(),
    });
    this.props.onWpmChange("");
  }

  start() {
    this.onReset();
  }

  updateWpm(wrongText, typedText) {
    const wpm = this.getWPM(typedText.length);
    this.setState({ currentWpm: wpm });
    this.props.onWpmChange(wpm, this.isFinished(wrongText, typedText));
  }

  getWPM(typedLength) {
    return Math.ceil(
      (typedLength * 12 * 1000) /
        (new Date().getTime() + 1 - this.state.startTime)
    );
  }

  getNextChar(typedLength, wrongText) {
    if (!this.state.isFocused) {
      return "";
    }
    if (wrongText) {
      return "_";
    }

    if (typedLength >= this.props.text.length) {
      return "";
    }

    let c = this.props.text.slice(typedLength, typedLength + 1);
    return c;
  }

  doesNextCharMatch(wrongText, typedText, c) {
    return !wrongText && this.getNextChar(typedText.length, wrongText) === c;
  }

  isFinished(wrongText, typedText) {
    return !this.state.wrongText && this.props.text === typedText;
  }

  getWrongText() {
    return this.state.wrongText;
  }
}
