import React from "react";
import "./App.css";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import TypingClient from "./TypingClient";
import TextProvider from "./TextProvider";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.client = new TypingClient();
    this.state = {
      username: "",
      inputUserName: "",
      currentWpm: "",
      bestWpm: "",
      snippetID: "",
      snippet: "",
      showEditName: "",
      token: "",
      restart: 0,
    };
  }

  render() {
    return (
      <div className="app primary-text">
        <div className="paper-sidebar paper-shadow"></div>
        <div className="ff"></div>
        <div className="sidebar">
          <div className="username primary-dark">{this.getUserNameDiv()}</div>
          <div className="score primary-main ">
            <div className="padding-top-left">Best WPM</div>

            <div className="score-text">{this.state.bestWpm}</div>
          </div>
          <div className="score primary-main ">
            <div className="padding-top-left">Current WPM</div>
            <div className="score-text">{this.state.currentWpm}</div>
          </div>
          <div className="primary-main-light">
            <div className="menu primary-main-light">
              <div className="spacing-menu"></div>
              <Button
                variant="contained"
                color="secondary"
                className="restart"
                onClick={this.restart}
                disableElevation
              >
                Restart
              </Button>
              <div className="spacing-menu"></div>
              <Button
                variant="contained"
                color="secondary"
                className="next"
                onClick={this.getNextSnippet}
                disableElevation
              >
                Next
              </Button>
              <div className="spacing-menu"></div>
            </div>
          </div>
          <div className="bottom"></div>
        </div>

        <TextProvider
          onWpmChange={this.handleWpmChange}
          text={this.state.snippet}
          restart={this.state.restart}
          onNext={this.getNextSnippet}
        />
      </div>
    );
  }

  getUserNameDiv() {
    if (!this.SignedIn()) {
      return (
        <div className={"padding-left " + (!this.SignedIn() ? "" : "hidden")}>
          &nbsp;<div id="g-signin2"></div>
        </div>
      );
    } else {
      let showName = <span>{this.state.username}?</span>;
      let editName = (
        <span>
          <Input
            style={{ width: 100, color: "white" }}
            placeholder={this.state.username}
            onChange={this.changeUserName}
            color="primary"
          />
        </span>
      );

      return (
        <div
          onMouseEnter={this.onMouseEnterName}
          onMouseLeave={this.onMouseLeaveName}
        >
          <div className="padding-top-left">
            Ready to type, {this.state.showEditName ? editName : showName}{" "}
          </div>
        </div>
      );
    }
  }

  onMouseEnterName = (e) => {
    this.setState({ showEditName: true });
  };

  onMouseLeaveName = (e) => {
    this.setState({ showEditName: false });
    this.setUserName(e);
  };

  handleWpmChange = (wpm, finished) => {
    this.setState({ currentWpm: wpm });
    if (finished && (!this.state.bestWpm || this.state.bestWpm < wpm)) {
      this.client.setScore(this.state.snippetID, wpm.toString());
      this.setState({ bestWpm: wpm });
    }
  };

  changeUserName = (e) => {
    this.setState({ inputUserName: e.target.value });
  };

  SignedIn() {
    return this.state.token;
  }

  onSignIn = (googleUser) => {
    let token = googleUser.getAuthResponse().id_token;
    localStorage.setItem("token", token);

    this.getUserName();
    this.getBestWpm();
    this.setState({ token: token });
  };

  componentDidMount() {
    window.gapi.load("auth2", () => {
      window.gapi.signin2.render("g-signin2", {
        scope: "profile email",
        width: 150,
        height: 50,
        onsuccess: this.onSignIn,
      });
    });

    this.getUserName();
    this.getBestWpm();
    this.getNextSnippet();
  }

  getUserName = (e) => {
    return this.client.getUserName().then((username) => {
      this.setState({ username: username });
    });
  };

  getBestWpm = (e) => {
    return this.getBestWpmFromSnippetID(this.state.snippetID);
  };

  getBestWpmFromSnippetID(snippetID) {
    return this.client.getScore(snippetID).then((score) => {
      this.setState({ bestWpm: score });
    });
  }

  setUserName = (e) => {
    let inputUserName = this.state.inputUserName;
    if (!inputUserName || inputUserName === this.state.username) {
      return;
    }
    this.setState({ username: inputUserName });
    new TypingClient().setUserName(inputUserName);
  };

  getScore = (e) => {
    this.client.getScore(this.state.snippetID);
  };

  setScore = (e) => {
    this.client.setScore(this.state.snippetID, this.state.score);
  };

  getNextSnippet = (e) => {
    this.client.getNextSnippet(this.state.snippetID).then((s) => {
      this.restart(e);
      this.setState({ snippet: atob(s["snippet"]), snippetID: s["snippetID"] });
      this.getBestWpmFromSnippetID(s["snippetID"]);
    });
  };

  restart = (e) => {
    this.setState({ restart: this.state.restart + 1 });
  };
}

export default App;
