// @flow
import axios from "axios";
import AxiosResponse from "axios";

export default class TypingClient {
  getUserName(): Promise<string> {
    return this.request("getUserName", {}).then((dict) => {
      return dict != null && "username" in dict ? dict["username"] : "";
    });
  }

  setUserName(username: string) {
    this.request("setUserName", { username: username });
  }

  getScore(snippetID: string): Promise<string> {
    return this.request("getScore", { snippetID: snippetID }).then((dict) =>
      dict != null && "score" in dict ? dict["score"] : ""
    );
  }

  setScore(snippetID: string, score: string) {
    this.request("setScore", { snippetID: snippetID, score: score });
  }

  getNextSnippet(snippetID: string): Promise<{ [string]: string }> {
    return this.request("getNextSnippet", { snippetID: snippetID });
  }

  request(path: string, jsonData: any): Promise<{ [string]: string }> {
    // axios.defaults.timeout = 500;

    return axios
      .post<{ [string]: string }>(
        "https://type-fast-293505-go-server-dot-type-fast-293505.wm.r.appspot.com/" +
          path,
        // "http://localhost:8080/" + path,
        {
          Token: localStorage.getItem("token"),
          JSON: JSON.stringify(jsonData),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response: AxiosResponse<{ [string]: string }>) => {
        return JSON.parse(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
